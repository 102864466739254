<template>
  <div>
    <a-modal v-model:visible="visible" title="病例详情" @ok="handleOk" okText="新建" cancelText="关闭" :afterClose="closeModal"
      width="50vw" :okButtonProps="{ style: { display: 'none' } }">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">

        <div class="detailModal line3">
          <div><label>客户：</label><span>{{ data?.customerName }}</span></div>
          <div><label>性别：</label><span>{{ data?.customer?.gender }}</span></div>
          <div><label>电话：</label><span>{{ data?.customer?.mobile }}</span></div>
        </div>
        <div class="detailModal">
          <div><label>门诊：</label><span>{{ data?.clubName }}</span></div>
          <div><label>日期：</label><span>{{ data?.date }}</span></div>
        </div>
        <div class="detailModal line1">
          <div><label>医生：</label><span>{{ data?.doctorName }}</span></div>
          <div><label>主诉：</label><span>{{ data?.chiefComplaint }}</span></div>
          <div><label>脉象：</label><span>{{ data?.tcmDiagnose }}</span></div>
        </div>
        <div class="tableStyle" v-for="item in data.prescriptions">
          <h2>{{ item.category }}</h2>
          <div class="detailModal">
            <div><label>贴数：</label><span>{{ item?.dosageDays }}</span></div>
            <div><label>费用：</label><span>{{ item?.amount }}</span></div>
          </div>
          <div class="detailModal line1">
            <div><label>关联疾病：</label><span>{{ data?.diseases }}</span></div>
            <div><label>关联症候：</label><span>{{ data?.syndromes }}</span></div>
          </div>
          <div class="detailModal line4">
            <div v-for="item2 in item.items"><label>{{ item2.name }}：</label><span>{{ item2?.quantity }}</span></div>
          </div>

        </div>
      </a-form>

    </a-modal>
  </div>
</template>

<script>
import { Detail } from "/src/api/b2b/listReports.js";
export default {
  components: {
  },
  data() {
    return {
      visible: this.modelValue,
      columns: [
        {
          title: "名称",
          key: "name",
          dataIndex: "name",
          width: "30%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "首字母",
          key: "nameInitial",
          dataIndex: "nameInitial",
          // ellipsis: true,
          width: "20%",
          slots: {
            customRender: "nameInitial"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {}
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  methods: {

    async detail() {
      console.log(this.record);
      let res = await Detail(this.record?.medicalRecordId);
      // let res = await Detail(37456);

      this.data = res.data;
      console.log(this.data);
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },

  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.tableStyle {
  border: 1px solid #f0f0f0;
  padding: 10px;
}

.thead {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5em;
}

.tr {
  display: flex !important;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
}

.tr>div {
  width: 12.5%;
  padding: 5px 12px;
}

.detailModal>div {
  margin-bottom: 8px;
}

.detailModal.line4 {
  border: 1px solid #f0f0f0;
  padding: 20px;
}

.detailModal.line4 label {
  width: 120px;
}

h2 {
  text-align: center;
  font-weight: bold;
}
</style>
